import i18next from 'i18next'
import { ReactNode } from 'react'

import PaymentGraphicPage from 'components/pageClient/applicationForm/2_PaymentGraphic/PaymentGraphicPage'
import BankCardForm from 'components/pageClient/applicationForm/4_BankCardPage/BankCardPage'
import VerificationPage from 'components/pageClient/applicationForm/5_verificationPage/verificationPage'
import AgreeNewLoanTermPage from 'components/pageClient/applicationForm/AgreeNewLoanTerm/AgreeNewLoanTermPage'
import BankIdPage from 'components/pageClient/applicationForm/BankIdPage/BankIdPage'
import CollateralPage from 'components/pageClient/applicationForm/CollateralPage/CollateralPage'
import CreditInfoPage from 'components/pageClient/applicationForm/CreditInfoPage/CreditInfoPage'
import GarantorPage from 'components/pageClient/applicationForm/garantorPage/GarantorPage'
import TranchesPage from 'components/pageClient/applicationForm/TranchesPage/TranchesPage'
import { ClientApplication } from 'components/pageClient/ClientApplication/ClientApplication'
import { BASE_URL, COUNTRY } from 'config'
import { IApplication } from 'redux/reducers/client/allState/types/stateTypes'
import { ICreditProduct } from 'redux/reducers/lender/allState/types/state'

export type TStepName =
  | 'product_info'
  | 'payment'
  | 'tranches'
  | 'test_application'
  | 'collateral'
  | 'agree_new_loan_term'
  | 'bank_card'
  | 'bank_id'
  | 'guarantor'
  | 'verification'

export interface IApplicationStep {
  name: TStepName
  isShow: boolean
  component: ReactNode
  title: string
}

export const getApplicationSteps = (policy: ICreditProduct, application?: IApplication | null) => {
  const { t } = i18next
  const { is_guarantor_required, is_tranches_enabled, collaterals, credit_type, is_prepayment } = policy

  const steps: IApplicationStep[] = [
    {
      name: 'product_info',
      isShow: is_prepayment,
      component: <CreditInfoPage />,
      title: t('client.applicationForm.steps.credit')
    },
    {
      name: 'payment',
      isShow: true,
      component: <PaymentGraphicPage />,
      title: t('client.applicationForm.steps.payment')
    },
    {
      name: 'tranches',
      isShow: is_tranches_enabled,
      component: <TranchesPage />,
      title: t('client.applicationForm.steps.tranches')
    },
    {
      name: 'test_application',
      isShow: true,
      component: <ClientApplication />,
      title: t('client.applicationForm.steps.applicationForm')
    },
    {
      name: 'collateral',
      isShow: !!collaterals.length,
      component: <CollateralPage />,
      title: t('client.applicationForm.steps.collateral')
    },
    {
      name: 'bank_id',
      // isShow: COUNTRY === 'Ukraine' && BASE_URL === 'https://cab.groshi247.com',
      isShow: true,
      component: <BankIdPage />,
      title: t('client.applicationForm.steps.bankId')
    },
    {
      name: 'bank_card',
      isShow: true,
      component: <BankCardForm />,
      title: t('client.applicationForm.steps.bankCard')
    },
    {
      name: 'guarantor',
      isShow: application
        ? is_guarantor_required
          ? true
          : application?.requested_guarantor
        : is_guarantor_required,
      component: <GarantorPage />,
      title: t('client.applicationForm.steps.guarantor')
    },
    {
      name: 'agree_new_loan_term',
      isShow: application
        ? +application?.risk_segment?.multiply_interest > 1 || +application?.risk_segment?.multiply_amount > 1
        : false,
      component: <AgreeNewLoanTermPage />,
      title: t('client.applicationForm.steps.agreeNewLoanTerm')
    },
    {
      name: 'verification',
      isShow: true,
      component: <VerificationPage />,
      title: t('client.applicationForm.steps.verification')
    }
  ]

  return steps.filter(({ isShow }) => isShow)
}
